import * as React from "react"

export default function GatsbyContentfulLogo() {
  return (
    <svg
      width="200"
      height="51"
      viewBox="0 0 200 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="173"
        y="37"
        width="35"
        height="27"
        transform="rotate(-90 173 37)"
        fill="#F288B9"
      />
      <rect y="23" width="43" height="17" fill="#F2E9A0" />
      <rect x="20" width="49" height="13" fill="#68D9EB" />
      <path
        d="M78.28 6.28001V13.24C78.28 19.06 78.28 24.16 78.46 29.5C77.08 26.62 75.64 23.74 74.32 21.46L65.5 6.28001H51.4V49H64.6V42.04C64.6 36.58 64.54 31.72 64.36 26.68C65.56 29.02 66.88 31.3 68.38 33.82L77.38 49H91.48V6.28001H78.28Z"
        fill="black"
      />
      <path
        d="M141.223 6.28001L138.823 18.94C137.863 23.98 136.963 29.08 136.063 34C135.223 29.08 134.203 23.98 133.243 18.94L130.723 6.28001H117.703L115.183 18.94C114.223 23.98 113.203 29.08 112.363 34C111.463 29.08 110.563 23.98 109.603 18.94L107.203 6.28001H93.3428L104.623 49H119.143L121.963 35.98C122.803 32.26 123.523 28.54 124.243 24.88C124.903 28.54 125.623 32.26 126.463 35.98L129.283 49H143.803L155.083 6.28001H141.223Z"
        fill="black"
      />
      <path
        d="M174.341 50.02C187.181 50.02 193.841 44.08 193.841 34.9C193.841 27.28 189.041 23.2 178.361 21.7L173.201 20.98C170.621 20.62 169.181 19.78 169.181 18.1C169.181 16.42 170.621 15.34 174.341 15.34C178.421 15.34 179.801 16.84 180.101 19.42H192.641C191.861 9.52001 185.021 5.32001 173.861 5.32001C159.221 5.32001 155.681 12.58 155.681 19.3C155.681 27.46 161.201 31.66 168.941 32.74L174.101 33.46C178.721 34.12 180.161 35.08 180.161 36.94C180.161 38.92 178.481 39.88 174.581 39.88C170.801 39.88 168.341 38.92 168.041 34.96H154.661C155.081 43.9 161.261 50.02 174.341 50.02Z"
        fill="black"
      />
      <rect x="55" y="22" width="121" height="11" fill="black" />
      <path
        d="M27 5.60938C16.504 5.60938 8 14.0645 8 24.5C8 34.9355 16.504 43.3906 27 43.3906C37.496 43.3906 46 34.9355 46 24.5C46 14.0645 37.496 5.60938 27 5.60938ZM41.8016 17.1875H35.481C34.7915 13.8055 33.6347 10.9262 32.1714 8.87715C36.3851 10.2635 39.848 13.2875 41.8016 17.1875ZM33.7419 24.5C33.7419 26.2443 33.6194 27.8668 33.4125 29.375H20.5875C20.3806 27.8668 20.2581 26.2443 20.2581 24.5C20.2581 22.7557 20.3806 21.1332 20.5875 19.625H33.4125C33.6194 21.1332 33.7419 22.7557 33.7419 24.5V24.5ZM27 8.04688C29.0609 8.04688 31.704 11.4061 32.9835 17.1875H21.0165C22.296 11.4061 24.9391 8.04688 27 8.04688ZM21.8286 8.87715C20.373 10.9186 19.2085 13.7979 18.519 17.1875H12.1984C14.152 13.2875 17.6149 10.2635 21.8286 8.87715V8.87715ZM10.4516 24.5C10.4516 22.8014 10.7121 21.1637 11.1948 19.625H18.1282C17.929 21.1865 17.8065 22.809 17.8065 24.5C17.8065 26.191 17.9214 27.8135 18.1282 29.375H11.1948C10.7121 27.8363 10.4516 26.1986 10.4516 24.5ZM12.1984 31.8125H18.519C19.2085 35.1945 20.3653 38.0738 21.8286 40.1229C17.6149 38.7365 14.152 35.7125 12.1984 31.8125ZM27 40.9531C24.9391 40.9531 22.296 37.5939 21.0165 31.8125H32.9835C31.704 37.5939 29.0609 40.9531 27 40.9531V40.9531ZM32.1714 40.1229C33.627 38.0814 34.7915 35.2021 35.481 31.8125H41.8016C39.848 35.7125 36.3851 38.7365 32.1714 40.1229ZM35.8718 29.375C36.071 27.8135 36.1935 26.191 36.1935 24.5C36.1935 22.809 36.0786 21.1865 35.8718 19.625H42.8052C43.2879 21.1637 43.5484 22.8014 43.5484 24.5C43.5484 26.1986 43.2879 27.8363 42.8052 29.375H35.8718Z"
        fill="black"
      />
    </svg>
  )
}
